<template>
  <div id="site-sections-list">
    <v-breadcrumbs
      divider="/"
      large
      :items="breadcrumbs"
    ></v-breadcrumbs>
    <v-card
      v-show="false"
      class="filter-section"
    >
      <v-row class="">
        <v-col cols="6">
          <v-card-title class="font-weight-bold ">
            {{ $t('common.list.filters') }}
          </v-card-title>
        </v-col>
        <v-col
          cols="6"
          class="d-flex justify-end"
          @click="updateFilteredSitePages()"
        >
          <v-btn
            icon
            @click="showFilter = !showFilter"
          >
            <v-icon>{{ showFilter ? icons.mdiMenuUp : icons.mdiMenuDown }}</v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-expand-transition>
        <v-row
          v-show="showFilter"
          class="px-2 ma-0"
        >
          <!-- role filter -->

          <v-col
            cols="12"
            sm="3"
          >
            <v-select
              v-model="filters.site_id"
              :placeholder="$t('common.list.selectSite')"
              :items="sitesList"
              item-text="url"
              item-value="id"
              single-line
              dense
              outlined
              clearable
              hide-details
            ></v-select>
          </v-col>
          <v-col
            cols="12"
            sm="3"
          >
            <v-select
              v-model="filters.page_id"
              :placeholder="$t('common.list.selectPage')"
              :items="filteredSitePages"
              item-text="href"
              item-value="id"
              single-line
              dense
              outlined
              clearable
              hide-details
            ></v-select>
          </v-col>
        </v-row>
      </v-expand-transition>
    </v-card>
    <v-row class="my-0 d-flex justify-end">
      <v-col
        cols="12"
        sm="2"
        style="margin-right:auto"
        align="right"
      >
        <v-btn
          color="primary"
          block
          class=""
          :loading="loading"
          :disabled="loading"
          @click="loadData"
        >
          <v-icon class="prefix-icon">
            {{ icons.mdiRefresh }}
          </v-icon>
          <span>
            {{ $t('common.list.refresh') }}
          </span>
        </v-btn>
      </v-col>
      <v-col
        cols="12"
        sm="2"
        align="right"
      >
        <v-btn
          depressed
          block
          color="success"
          @click="setAside(null)"
        >
          <v-icon class="prefix-icon">
            {{ icons.mdiPlus }}
          </v-icon>
          {{ $t('common.list.create') }}
        </v-btn>
      </v-col>
    </v-row>

    <v-card>
      <!-- table -->
      <v-data-table
        :headers="tableColumns"
        :items="tableItems"
        :options.sync="options"
        :server-items-length="tableTotal"
        :loading="loading"
        class="text-no-wrap"
        :header-props="headerprops"
      >
        <!-- key -->
        <template #[`item.key`]="{ item }">
          <router-link
            class="font-weight-medium text-decoration-none"
            :to="{ name: 'site-items', params: { site_id: router.currentRoute.params.site_id, site_page_id: item.page_id, site_section_id: item.id} }"
          >
            {{ item.key }}
          </router-link>
        </template>

        <!-- element -->
        <template #[`item.element`]="{ item }">
          <span class="text-wrap">{{ item.element }}</span>
        </template>

        <template #[`item.selection`]="{ item }">
          <router-link
            v-if="item.selection"
            class="text-wrap"
            :to="{ name: 'selection-view', params: { id: item.selection_id } }"
          >
            {{ item.selection?.name?.en }}
          </router-link>
          <span v-else>--</span>
        </template>

        <!-- element -->
        <template #[`item.feature_id`]="{ item }">
          <span class="text-wrap">{{ item.feature?.key || '--' }}</span>
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{ item }">
          <div class="d-flex align-center justify-center">
            <!-- dropdown -->
            <v-menu
              bottom
              left
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  small
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon size="18">
                    {{ icons.mdiDotsVertical }}
                  </v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item
                  link
                  @click="setAside(item)"
                >
                  <v-list-item-title>
                    <v-icon
                      size="18"
                      class="me-2"
                    >
                      {{ icons.mdiPencilOutline }}
                    </v-icon>
                    <span>Edit</span>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item
                  v-if="!item.reserved"
                  link
                  @click="deleteSiteSections(item)"
                >
                  <v-list-item-title>
                    <v-icon
                      size="18"
                      class="me-2"
                    >
                      {{ icons.mdiDeleteOutline }}
                    </v-icon>
                    <span>Delete</span>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </template>
      </v-data-table>
    </v-card>

    <template v-if="aside">
      <!-- <faq-section-creation-aside
        v-if="pageHref === '/faq' && !resource"
        v-model="aside"
        @changed="loadData(); aside = false"
      /> -->

      <site-sections-aside
        v-model="aside"
        :resource="resource"
        :table-list="tableList"
        :page-name="pageHref"
        @changed="loadData(); aside = false"
      />
    </template>

    <!-- <form-aside
      v-if="aside"
      v-model="aside"
      :resource="resource"
      @changed="
        loadCards()
        aside = false
      "
    ></form-aside> -->
  </div>
</template>

<script>
import {
mdiDeleteOutline, mdiDotsVertical,
mdiMenuDown,
mdiMenuUp,
mdiPencilOutline,
mdiPlus,
mdiRefresh,
} from '@mdi/js'

import ImportDialog from '@/modules/doctor/components/ImportDialog'
import useSites from '@/modules/site/views/useSites'
import { t } from '@/plugins/i18n'
import router from '@/router'
import { formatDate } from '@core/utils/filter'
import {
computed,
onBeforeMount,
onMounted,
ref,
watch,
} from '@vue/composition-api'
import SiteSectionsAside from './site-sections-resource/SiteSectionsAside.vue'
import useSiteSections from './useSiteSections'

export default {
  components: {
    ImportDialog,
    SiteSectionsAside,
  },
  setup() {
    const {
      tableItems, tableColumns, tableTotal, options, filters, loading, loadData, deleteSiteSections,
    } = useSiteSections()
    const {
      sitesList, loadSites, sitePagesList, loadSitePages,
    } = useSites()

    const siteId = computed(() => parseInt(router.currentRoute.params.site_id))
    const pageId = computed(() => parseInt(router.currentRoute.params.site_page_id))

    const page = computed(() => sitePagesList.value.find(p => p.id === pageId.value))
    const pageHref = computed(() => page.value?.href)

    onMounted(async () => {
      await loadSites()
      await loadSitePages()

      filters.site_id = siteId.value
      filters.page_id = pageId.value

      await updateFilteredSitePages()
    })

    onBeforeMount(async () => {})

    const breadcrumbs = ref([
      {
        text: 'Site Info',
        disabled: false,
        href: '/sites',
      },
      {
        text: 'Site Pages',
        disabled: false,
        href: `/sites/${router.currentRoute.params.site_id}/site-pages`,
      },
      {
        text: 'Site Sections',
        disabled: true,
        href: `/sites/${router.currentRoute.params.site_id}/site-pages/${router.currentRoute.params.site_page_id}/site-sections`,
      },
    ])

    const actions = ['Edit']

    const tableList = ref([])

    // console.log('sitePagesList', sitePagesList.value)

    const aside = ref(false)
    const setAside = item => {
      aside.value = true
      resource.value = item
      tableList.value = tableItems.value
    }

    const resource = ref(null)

    // const categories = ref([])

    // const loadCategories = async () => {
    //   try {
    //     const {
    //       status,
    //       data: {
    //         data: { records },
    //       },
    //     } = await fetchDistributors()

    //     if (status === 200) {
    //       categories.value = records.map(record => {
    //         return { title: record.name.en, value: record.id }
    //       })
    //     }
    //   } catch (error) {
    //     console.log(error)
    //   }
    // }

    // loadCategories()

    const singleSelect = ref(false)
    const headerprops = ref({
      'sort-icon': mdiMenuDown,
    })
    const showFilter = ref(true)

    const filteredSitePages = ref(null)

    const updateFilteredSitePages = () => {
      console.log('updateFilteredSitePages')
      if (filters && filters.site_id) {
        filteredSitePages.value = sitePagesList.value.filter(page => page.site_id === filters.site_id)
      } else {
        console.log('sitePagesList.value', sitePagesList.value)
        console.log('filteredSitePages.value', filteredSitePages.value)
        filteredSitePages.value = sitePagesList.value
      }
    }

    watch(
      () => filters.site_id,
      () => {
        console.log('filters.site_id', filters.site_id)
        updateFilteredSitePages()
      },
    )

    return {
      loading,
      t,
      formatDate,
      icons: {
        mdiDotsVertical,
        mdiPencilOutline,
        mdiDeleteOutline,
        mdiMenuDown,
        mdiMenuUp,
        mdiRefresh,
        mdiPlus,
      },

      // categories,
      setAside,
      aside,
      resource,

      tableColumns,
      tableItems,
      tableTotal,
      options,
      filters,

      actions,

      sitesList,
      sitePagesList,
      filteredSitePages,

      loadData,
      deleteSiteSections,
      singleSelect,
      headerprops,
      showFilter,
      updateFilteredSitePages,
      router,
      breadcrumbs,
      tableList,

      page,
      pageHref,
    }
  },
}
</script>

<style lang="scss" scoped>
</style>
